import React from 'react';
import './Footer.scss';

function Component()
{
	const html =
	(
		<>
		</>
	);

	return html;
}

export default Component;
