
function Component()
{
	return <main>
			<p>
				The tool has been deprecated.<br/><br/>
				Please refer to <a href="https://nervos.gitbook.io/layer-2-evm/tasks/1.create.godwoken.account">https://nervos.gitbook.io/layer-2-evm/tasks/1.create.godwoken.account</a> to find new instruction.
			</p>
		</main>;
}

export default Component;
